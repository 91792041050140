/* You can add global styles to this file, and also import other style files */
:root {
  --jaimy-blue: #3966f6;
  --jaimy-light-blue: #62c7d7;
  --jaimy-dark-yellow: #edb358;
  --jaimy-dark-grey: #556876;
  --jaimy-background: #f5f5f5;
  --jaimy-text: #405a6b;
  --jaimy-default-text: #3c4043;
  --jaimy-TextLight: #778d9b;
  --jaimy-light-grey: #f4f4f4;
  --jaimy-grey: #e8ebed;
  --jaimy-appbar: #fefefe;
  --jaimy-green: #72c319;
  --jaimy-stage-raw-deal: #af1e3c;
  --jaimy-stage-pending-call: #4087da;
  --jaimy-stage-appointment: #69beb2;
  --jaimy-stage-live: #ffa000;
  --jaimy-stage-sold: #c0ca33;
  --jaimy-stage-lost: #9e9e9e;
  --jaimy-stage-on-sale: #ff0009;
  --jaimy-stage-republished: #5c6bc0;
  --jaimy-light-red: #f03241;
  --jaimy-dark-red: #af1e3c;
  --jaimy-indigo: #5c6bc0;
  --jaimy-pink: #b241bd;
  --jaimy-error: rgb(183, 28, 28, 0.8);
  --jaimy-expert: #ffb55a;
  --jaimy-agency: #5affc5;

  --rik-belfius-red: #d1184d;
  --rik-dvv-orange: #ff9e16;
  --rik-corona-blue: #009ee3;

  --rik-claim-received: #af1e3c;
  --rik-claim-in-progress: #00aeff;
  --rik-claim-qs-survey: #0d6e7a;
  --rik-claim-qs-call-2: #0a5761;
  --rik-claim-qs-call-3: #083a41;
  --rik-claim-success-admin-tasks: #90be6d;
  --rik-claim-success: #3966f6;
  --rik-claim-info-insurer-needed: #4d908e;
  --rik-claim-info-client-needed: #bd7ebe;
  --rik-claim-info-client-unreachable: #582458;
  --rik-claim-expertise: #ffb55a;
  --rik-claim-complaint: #f03241;
  --rik-claim-trashed: #9e9e9e;
  --rik-claim-closed: #0f01d3;

  --rik-intervention-leak-detection-date-required: #b0279e;
  --rik-intervention-leak-detection-planned: #4d0e44;
  --rik-intervention-wait-for-leak-detection-report: #a1aa1e;
  --rik-intervention-leak-detection-report: #bbc351;

  --rik-intervention-date-inspection-required: #21d4f3;
  --rik-intervention-inspection-planned: #2196f3;
  --rik-intervention-waiting-for-quote: #6373ca;
  --rik-intervention-quote-evaluation: #b2b67e;

  --rik-intervention-date-repair-intervention-required: #673ab7;
  --rik-intervention-repair-intervention-planned: #321466;
  --rik-intervention-waiting-for-invoice: #af8e20;
  --rik-intervention-process-invoice: #c9bd12;

  --rik-intervention-info-insurer-needed: #7851bb;
  --rik-intervention-info-client-needed: #bd7ebe;
  --rik-intervention-info-pro-needed: #4133bb;
  --rik-intervention-expertise: #ffb55a;
  --rik-intervention-complaint: #f03241;
  --rik-intervention-quality-issue: #501405;
  --rik-intervention-trashed: #9e9e9e;
  --rik-intervention-no-cost: #0f01d3;
  --rik-intervention-paid: #8bc34a;

  --teledesk-main: #0089b7;
}
